.label-value-box {
    align-items: center;
    border-bottom: 1px solid lightgrey;
    display: flex;
    padding: 8px;
}

.label-value-box-label {
    width: 200px;
}

.content-card {
    border-left: 3px solid #ff6a00;
    margin: 8px;
}

.clickable:hover {
    background-color: #efefef;
    border-radius: 8px;
    cursor: pointer;
}

.clickable:active {
    background-color: #fffaf5;
}

.menu-item {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    margin: 4px;
    transition: background-color 0.3s;
}

.menu-item:hover {
    background-color: #efefef;
    border-radius: 8px;
    cursor: pointer;
}

.menu-item:active {
    background-color: #5ae0cf;
}